import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import L from 'leaflet'
import React, { memo, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import { Poi } from './Poi'

export interface Props {
  address?: string
  googleMapsURL?: string
  languageCode: string
  latitude?: string
  longitude?: string
  poiList?: string[]
  title?: string
  zoom?: number
}

export const Directions = memo(function Directions({
  address,
  googleMapsURL,
  languageCode,
  latitude,
  longitude,
  poiList,
  title,
  zoom,
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  const markerIconTrain = `<svg xmlns="http://www.w3.org/2000/svg" width="24.995" height="30" viewBox="0 0 24.995 30"><path d="M-14848.72,7246l-2-6h-10.5l-2,6h-3.164l2.156-6.455h0a6.652,6.652,0,0,1-3.076-2.443,6.651,6.651,0,0,1-1.166-3.752,7.3,7.3,0,0,1,.051-.825l1.736-13.895.33-2.63h20.77l.324,2.63,1.734,13.915a6.63,6.63,0,0,1-.891,4.218,6.643,6.643,0,0,1-3.293,2.782l2.148,6.455Zm-16.721-13.1a3.476,3.476,0,0,0-.031.45,3.656,3.656,0,0,0,1.064,2.579,3.646,3.646,0,0,0,2.576,1.071h11.719a3.653,3.653,0,0,0,2.578-1.066,3.654,3.654,0,0,0,1.068-2.573,3.629,3.629,0,0,0-.029-.461h0l-.615-4.9h-17.719Zm1.721-13.9-.75,6h17l-.764-6Zm10.75,13.75a1.749,1.749,0,0,1,1.748-1.75,1.75,1.75,0,0,1,1.752,1.75,1.751,1.751,0,0,1-1.752,1.75A1.749,1.749,0,0,1-14852.97,7232.748Zm-9.5,0a1.751,1.751,0,0,1,1.752-1.75,1.75,1.75,0,0,1,1.75,1.75,1.75,1.75,0,0,1-1.75,1.75A1.751,1.751,0,0,1-14862.472,7232.748Z" transform="translate(14868.472 -7215.998)" fill="#1f1f1f"/></svg>`
  const markerIconPlane = `<svg xmlns="http://www.w3.org/2000/svg" width="29.976" height="30" viewBox="0 0 29.976 30"><path d="M187.027,139.66l4.031,4.031a3.023,3.023,0,0,0,4.278,0l1.008-1.008a3.023,3.023,0,0,0,.811-2.791l-.64-2.872,2.726-2.625,3.335,6.525h0a3.023,3.023,0,0,0,4.827.761l2.015-2.015h0a3.022,3.022,0,0,0,.867-2.645l-1.758-10.581,2.1-2.1h0a5.875,5.875,0,0,0-8.309-8.279l-2.1,2.1L189.667,116.4a3.024,3.024,0,0,0-2.635.847l-2.015,2.015a3.023,3.023,0,0,0,.816,4.857l6.182,3.023-2.484,3.023-2.726-.6h0a3.023,3.023,0,0,0-2.791.811l-1.008,1.008a3.023,3.023,0,0,0,0,4.278Zm-.887-7.175,4.535,1.008,6.107-7.412-9.634-4.681,2.015-2.015,12.093,2.015,3.195-3.195h0a2.852,2.852,0,1,1,4.031,4.031l-3.195,3.195,2.015,12.093-2.015,2.015L200.1,129.4l-6.908,6.611,1.008,4.535-1.008,1.008-8.062-8.062Z" transform="translate(-182.12 -114.578)" fill="#1f1f1f"/></svg>`

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [66, 80],
        iconAnchor: [33, 80],
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="60" viewBox="0 0 50 60"><path d="M188.339,56a25,25,0,0,0-17.674,42.675L188.339,116,206.02,98.671A25,25,0,0,0,188.34,56Zm0,37.5a12.5,12.5,0,1,1,12.5-12.5A12.5,12.5,0,0,1,188.339,93.5Z" transform="translate(-163.34 -56)" fill="#5a8682" /></svg>`,
        className: 'main-icon',
      }),
    )
  }, [])

  return (
    <Container row stretch wrap>
      <LeftSide>
        {title ? (
          <Title>
            <Line />
            {title}
          </Title>
        ) : null}

        {address ? (
          <Address dangerouslySetInnerHTML={{ __html: address }} />
        ) : null}

        {googleMapsURL ? (
          <GoogleMapsCTA href={googleMapsURL} rel="noreferrer" target="_blank">
            {useVocabularyData('open-in-google-maps', languageCode)}
          </GoogleMapsCTA>
        ) : null}

        {poiList ? (
          <PoiList>
            {poiList.map((item, index) => (
              <Poi key={index} {...item} />
            ))}
          </PoiList>
        ) : null}
      </LeftSide>

      <RightSide>
        <Map>
          {typeof window !== undefined && latitude && longitude ? (
            <MapContainer
              center={[Number(latitude), Number(longitude)]}
              dragging={L.Browser && L.Browser.mobile ? false : true}
              zoom={Number(zoom)}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              />
              <Marker
                icon={markerIcon}
                position={[Number(latitude), Number(longitude)]}
              />

              {poiList
                ? poiList.map((item: any, index) => (
                    <Marker
                      key={index}
                      icon={
                        typeof window !== 'undefined'
                          ? L.divIcon({
                              iconSize: [40, 40],
                              iconAnchor: [20, 20],
                              html:
                                item.icon === 'train'
                                  ? markerIconTrain
                                  : markerIconPlane,
                              className: 'poi-icon',
                            })
                          : undefined
                      }
                      position={[
                        Number(item.latitude) || 0,
                        Number(item.longitude) || 0,
                      ]}
                    />
                  ))
                : null}
            </MapContainer>
          ) : null}
        </Map>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 225px 0 260px;

  @media (max-width: 1023px) {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
`

const LeftSide = styled.div`
  width: 33.333%;
  padding: 0 8.333vw 0 10vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 1.3125rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin-bottom: 3.75rem;

  @media (max-width: 1023px) {
    margin-bottom: 1.875rem;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2.125rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 2rem;

    br {
      content: ' ';
      &:after {
        content: ' ';
      }
    }
  }
`

const GoogleMapsCTA = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2.125rem;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.primaryDark};
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 2rem;
  }
`

const PoiList = styled.div`
  margin-top: 5.625rem;

  @media (max-width: 1023px) {
    display: none;
  }
`

const RightSide = styled.div`
  width: 66.666%;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 40px;
  }
`

const Map = styled.div`
  height: 77.8vh;
  position: relative;

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .leaflet-marker-icon {
    border: 0;
    &.poi-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      border-radius: 50%;
      svg {
        width: auto;
        height: 1rem;
      }
    }
    &.main-icon {
      background: none;
      border-radius: 0;
    }
  }

  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: 400;
    .leaflet-control {
      display: flex;
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 3rem;
        height: 3rem;
        line-height: 2.875rem;
        background: ${({ theme }) => theme.colors.variants.secondaryLight};
        border: 0;
        border-radius: 0 !important;
        position: relative;
        text-indent: -9999px;
        transition: 0.2s ease-out;
        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.secondaryDark};
          }
          &:before {
            content: '';
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralLight4};
            position: absolute;
            top: 50%;
            left: 50%;
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-control-zoom-in {
          margin-right: 0.0625rem;
          &:after {
            content: '';
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralLight4};
            position: absolute;
            top: 50%;
            left: 50%;
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;
          &:hover {
            opacity: 0.2;
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-bottom {
    display: none;
  }

  @media (max-width: 1023px) {
    height: 50vh;

    .leaflet-marker-icon {
      &.poi-icon {
        display: none;
      }
    }

    .leaflet-left {
      display: none;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 33.5vh;
  }
`
