import styled from '@emotion/styled'
import { Plane, Train } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  distance?: string
  icon?: string
  label?: string
}

export const Poi = memo(function Poi({ distance, icon, label }: Props) {
  let iconDom

  switch (icon) {
    case 'plane':
      iconDom = <Plane />
      break

    default:
      iconDom = <Train />
      break
  }

  return (
    <Container>
      {iconDom}
      <Label>{label}</Label>
      {distance ? <Distance>{distance}</Distance> : null}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 2.5rem;
  &:first-of-type {
    margin-top: 0;
  }

  svg {
    width: auto;
    height: 1.875rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-bottom: 0.5625rem;
  }
`

const Label = styled.div``

const Distance = styled.div`
  font-weight: 600;
`
